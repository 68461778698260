/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import EndpointsConst from "../consts/EndpointsConst";
import { getAPIUrl } from "../utilities/APIHelper";

class AVAService {
  /**
   * [GET]
   * Adds a site view to the domain
   * @returns {Promise}
   */
  addSiteView(domain) {
    let url =
      getAPIUrl() +
      EndpointsConst.API.AVA.ADD_SITE_VIEW.replace("{domain}", domain);
    return axios.get(url);
  }

  /**
   * [GET]
   * Retrieves site view count from database
   * @returns {Promise}
   */
  getSiteViewCount(domain) {
    let url =
      getAPIUrl() +
      EndpointsConst.API.AVA.GET_SITE_VIEW_COUNT.replace("{domain}", domain);
    return axios.get(url);
  }

  /**
   * [POST]
   * Submits customer contact form
   * @returns {Promise}
   */
  submitContactForm({ subject, name, email, phoneNumber, message }) {
    return axios({
      method: "post",
      url: getAPIUrl() + EndpointsConst.API.AVA.SUBMIT_CONTACT_FORM,
      headers: {},
      data: { subject, name, email, phoneNumber, message },
    });
  }

  /**
   * [POST]
   * Submits user's help request
   * @returns {Promise}
   */
  submitHelpRequest({ firstName, lastName, email, message }) {
    return axios({
      method: "post",
      url: getAPIUrl() + EndpointsConst.API.AVA.SUBMIT_HELP_REQUEST,
      headers: {},
      data: { firstName, lastName, email, message },
    });
  }
}

export default new AVAService();
