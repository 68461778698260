import { AppBar, IconButton, Link } from "@mui/material";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import RoutesConst from "../../consts/RoutesConst";
import logo from "../../images/logos/logo.png";
import GeneralStrings from "../../strings/GeneralStrings";
import classNames from "../../utilities/classNames";
import "./HomepageNavbar.scss";
import MobileNavDrawer from "./MobileNavDrawer";

const HomepageNavbar = ({ transparentNavbar = false }) => {
  const [isTransparent, setIsTransparent] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const logoText = GeneralStrings.FLORAL_DESIGN_LAB;
  const navBarStrings = GeneralStrings.NAVBAR;

  const handleTransparency = () => {
    if (window.scrollY >= 500) {
      setIsTransparent(false);
    } else {
      setIsTransparent(true);
    }
  };

  transparentNavbar && window.addEventListener("scroll", handleTransparency);

  return (
    <AppBar {...classNames("homepage-navbar", !isTransparent && "solid-nav")}>
      <div className="flex">
        <RouterLink to={RoutesConst.LOGIN} className="logo-link">
          <img src={logo} alt="Logo" className="navbar-logo" />
          <span className="fs-22 logo-text">{logoText}</span>
        </RouterLink>
        <div className="nav-link-container flex">
          <RouterLink to={RoutesConst.DEFAULT} className="nav-link">
            {navBarStrings.HOME}
          </RouterLink>
          <RouterLink to={RoutesConst.EVENTS} className="nav-link">
            {navBarStrings.EVENTS}
          </RouterLink>
          <RouterLink to={RoutesConst.PHOTO_GALLERY} className="nav-link">
            {navBarStrings.PHOTO_GALLERY}
          </RouterLink>
          <RouterLink to={RoutesConst.CONTACT} className="nav-link">
            {navBarStrings.CONTACT}
          </RouterLink>
          <div className="nav-social-links-container">
            <Link
              href="https://www.instagram.com/floraldesignlabindy/"
              target="_blank"
              className="nav-social-link"
            >
              <i className="fa-brands fa-instagram social-icon fa-lg"></i>
            </Link>
            <Link
              href="https://www.facebook.com/profile.php/?id=61552899370557"
              target="_blank"
              className="nav-social-link"
            >
              <i className="fa-brands fa-facebook-square social-icon fa-lg"></i>
            </Link>
            <Link
              href="https://www.linkedin.com/company/floraldesignlab/about/"
              target="_blank"
              className="nav-social-link"
            >
              <i className="fa-brands fa-linkedin social-icon fa-lg"></i>
            </Link>
          </div>
        </div>
        <div className="nav-drawer-toggle">
          <IconButton
            size="medium"
            className="nav-drawer-toggle-button"
            onClick={() => setIsDrawerOpen(true)}
          >
            <i className="fa-solid fa-bars"></i>
          </IconButton>
          <MobileNavDrawer
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
        </div>
      </div>
    </AppBar>
  );
};

export default HomepageNavbar;
